import React, { useMemo } from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { Fade, Box, Center, Divider, Heading } from '@chakra-ui/react';
import Select from 'react-select';
import { useFlexSearch } from 'react-use-flexsearch';
import queryString from 'query-string';
import { RecommendationWidget } from 'exoclick-react';

import { useDebounce } from '@hooks/';
import {
  BaseLoading,
  BasePagination,
  BaseScene,
  BaseSeo,
} from '@components/base';
import { TapeList } from '@components/tape';

const DELAY = 600;

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderColor: 'rgba(255, 255, 255, 0)',
    borderRadius: '6px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? data.color
      : isFocused
      ? '#d31027'
      : undefined,
    color: isDisabled ? '#ccc' : isSelected ? '#fff' : data.color,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled
        ? isSelected
          ? data.color
          : 'red'
        : undefined,
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#000',
    borderRadius: '999px',
    overflow: 'hidden',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    paddingInline: '16px 4px',
    color: '#fff',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    paddingInline: '8px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    ':hover': {
      backgroundColor: '#d31027',
    },
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#303030',
    zIndex: 3,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#fff',
  }),
};

export default function TagsPage({
  data: {
    allTag,
    localSearchTapes: { index, store },
  },
  location: { search },
}) {
  const { q: keyword } = queryString.parse(search);
  const [loading, setLoading] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState('');
  const debounceSelectedTags = useDebounce(selectedTags, DELAY);

  const currentPage = useMemo(() => {
    const { page } = queryString.parse(search);
    return page ? +page : 1;
  }, [search]);

  const tags = React.useMemo(
    () => allTag.nodes.map(({ id, name }) => ({ label: name, value: id })),
    [allTag]
  );

  const selectedTagValues = React.useMemo(
    () =>
      !!keyword
        ? tags.filter((t) =>
            keyword
              .trim()
              .split(',')
              .find((k) => t.label === k)
          )
        : [],
    [tags, keyword]
  );

  const totalTapes = useFlexSearch(debounceSelectedTags, index, store, {
    index: 'tagNames',
  });

  const tapes = React.useMemo(() => {
    const start = (currentPage - 1) * +process.env.GATSBY_TAPES_PER_PAGE;
    return totalTapes.slice(start, start + +process.env.GATSBY_TAPES_PER_PAGE);
  }, [totalTapes, currentPage]);

  const numPages = React.useMemo(
    () => Math.ceil(totalTapes.length / process.env.GATSBY_TAPES_PER_PAGE),
    [totalTapes]
  );

  const headerText = React.useMemo(() => {
    const page = currentPage > 1 ? `, Page ${currentPage}` : '';
    return `Showing ${totalTapes.length} items${page}`;
  }, [totalTapes, currentPage]);

  React.useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => setLoading(false), DELAY + 200);

    if (!keyword || !keyword.trim()) {
      return;
    }
    setSelectedTags(keyword.trim().replaceAll(',', ' '));

    return () => {
      clearTimeout(timeout);
    };
  }, [keyword, currentPage]);

  const handleSelectChange = React.useCallback((value) => {
    navigate(
      `?q=${value
        .map((v) => v.label)
        .join(',')
        .replace(/ /g, '%20')}`
    );
  }, []);

  const handleSelectPage = React.useCallback(
    (page) => {
      const safeKeyword = keyword.trim().replace(/ /g, '%20');
      const path =
        page > 1 ? `?q=${safeKeyword}&page=${page}` : `?q=${safeKeyword}`;
      // navigate(path);
      window.location.href = path;
    },
    [keyword]
  );

  return (
    <BaseScene as='section'>
      <Box pos='relative'>
        <Box mb={4}>
          <Select
            aria-label='select tags'
            placeholder='Select tags'
            styles={colourStyles}
            options={tags}
            value={selectedTagValues}
            closeMenuOnSelect={false}
            onChange={handleSelectChange}
            isMulti
          />
        </Box>
        <Fade in={loading}>
          <BaseLoading pos='absolute' w='full' paddingBlock={16} />
        </Fade>
        <Box opacity={loading ? 0 : 1}>
          <Box as='header' mb={{ base: 3, '2xs': 6 }}>
            <Heading
              as='h1'
              mb={{ base: 1, '2xs': 2 }}
              textAlign='center'
              fontSize={{ base: 'md', '2xs': 'lg' }}
            >
              {headerText}
            </Heading>
            <Divider />
          </Box>
          <TapeList tapes={tapes} tapeTitleProps={{ as: 'h2' }} />
          <Center pt={4} pb={2}>
            <RecommendationWidget zoneId={4856200} />
          </Center>
          {!!tapes.length && (
            <BasePagination
              mb={2}
              numPages={numPages}
              currentPage={currentPage}
              onSelectPage={handleSelectPage}
            />
          )}
        </Box>
      </Box>
      <Divider />
    </BaseScene>
  );
}

export function Head({ data }) {
  const { pathname } = useLocation();
  const { siteUrl, siteTitle, datePublished } = data.site.siteMetadata;

  const schemaGraphs = React.useMemo(() => {
    const pageUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: `Tags ∘ ${siteTitle}`,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        datePublished,
        dateModified: datePublished,
        breadcrumb: {
          '@id': `${pageUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ];
  }, [pathname, siteUrl, siteTitle, datePublished]);

  return (
    <BaseSeo
      title='Tags'
      description='Show Video by Tags'
      schemaGraphs={schemaGraphs}
    />
  );
}

export const query = graphql`
  query tagsQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        datePublished
      }
    }
    localSearchTapes {
      index
      store
    }
    allTag(sort: { fields: name, order: ASC }) {
      nodes {
        id: sbId
        name
      }
    }
  }
`;
